@use 'ht-styleguide/build/variables';

.cancelled_text {
  color: variables.$ht-red-orange-700;
}
.cancelled_icon {
  color: variables.$ht-red-orange-700;
}
.unserviceable_text {
  color: variables.$ht-red-orange-700;
}
.unserviceable_icon {
  color: variables.$ht-red-orange-700;
}
.completed_text {
  color: variables.$ht-success-green-500;
}
.completed_icon {
  color: variables.$ht-success-green-700;
}
.needs_approval_text {
  color: variables.$ht-blue-700;
}
.needs_approval_icon {
  color: variables.$ht-blue-700;
}
.not_started_text {
  color: variables.$ht-navy-700;
}
.not_started_icon {
  color: variables.$ht-navy-700;
}
.pending_approval_text {
  color: variables.$ht-blue-700;
}
.pending_approval_icon {
  color: variables.$ht-blue-700;
}
.in_progress_text {
  color: variables.$ht-dark-blue-700;
}
.in_progress_icon {
  color: variables.$ht-dark-blue-700;
}
.flagged_text {
  color: variables.$ht-red-orange-700;
}
.flagged_icon {
  color: variables.$ht-red-orange-700;
}
