@use 'ht-styleguide/build/variables';
@use 'ht-styleguide/build/spacing';
@use 'src/styles/grid_mdu';

.cursor {
  cursor: pointer;
}

.hoverStyles100 {
  &:hover {
    background-color: variables.$ht-navy-100;
  }
}

.focusStyles {
  &:focus {
    border: 1px solid variables.$ht-blue-700;
  }
}

.white-space-no-wrap {
  white-space: nowrap;
}

.underline {
  text-decoration: underline;
}

// Position
.position {
  &.relative {
    position: relative;
  }
  &.absolute {
    position: absolute;
  }
}

// Font weights
.text-weight-regular {
  font-weight: variables.$ht-font-weight-regular;
}
.text-weight-medium {
  font-weight: variables.$ht-font-weight-medium;
}
.text-weight-bold {
  font-weight: variables.$ht-font-weight-medium;
}

// Text alignment
.text-align-center {
  text-align: center !important;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}

.text-link {
  color: variables.$ht-teal-500;
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

.hidden {
  display: none;
}

.overflow-hidden {
  overflow: hidden;
}

.full-width {
  width: 100%;
}

.plainButton {
  appearance: none;
  padding: 0;
  background-color: transparent;
  border: none;
  margin: 0;
  &:focus,
  &:active {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}

.plainLink {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
  &:visited {
    color: inherit;
  }
}

/* Icons */
.icon-xsmall {
  font-size: 8px;
}

.icon-small {
  font-size: 12px;
}

.icon-medium {
  font-size: 16px;
}

.icon-large {
  font-size: 22px;
}

.icon-xlarge {
  font-size: 32px;
}

/* Box Shadows */
.box-shadow-bottom-small {
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.4);
}

// Layout
.fullwidth {
  width: 100%;
}

.fullheight {
  height: 100%;
}

.flex {
  display: flex;
  &.flex-1 {
    flex: 1;
  }
  &.flexDirectionColumn {
    flex-direction: column;
  }
  &.justifyContentCenter {
    justify-content: center;
  }
  &.justifyContentRight {
    justify-content: right;
  }
  &.alignItemsCenter {
    align-items: center;
  }
  &.alignItemsEnd {
    align-items: flex-end;
  }
  &.alignItemsBaseline {
    align-items: baseline;
  }
  &.justifyContentSpaceBetween {
    justify-content: space-between;
  }
  &.justifyContentSpaceAround {
    justify-content: space-around;
  }
  &.alignItemsFlexStart {
    align-items: flex-start;
  }
  &.alignItemsBaseline {
    align-items: baseline;
  }
  &.justifyContentFlexEnd {
    justify-content: flex-end;
  }
}

.gridSidePadding {
  @include spacing.spacing(padding-left, grid_mdu.$grid-sm-sideMargin);
  @include spacing.spacing(padding-right, grid_mdu.$grid-sm-sideMargin);
}

// Colors
.n300 {
  color: variables.$ht-navy-300;
}
.n700 {
  color: variables.$ht-navy-700;
}
.n800 {
  color: variables.$ht-navy-800;
}
.n900 {
  color: variables.$ht-navy-900;
}
.n000 {
  color: variables.$ht-navy-000;
}
.teal500 {
  color: variables.$ht-teal-500;
}
.white {
  color: variables.$ht-white;
}
.blue700 {
  color: variables.$ht-blue-700;
}
.lightBlue {
  color: variables.$ht-blue-500;
}
.lightBlue300 {
  color: variables.$ht-blue-300;
}
.lightBlue100 {
  color: variables.$ht-blue-100;
}
.alertRed500 {
  color: variables.$ht-alert-red-500;
}
.successGreen500 {
  color: variables.$ht-success-green-500;
}

// Others
.border-top {
  border-top: 1px solid variables.$ht-navy-100;
}

.border-top-2 {
  border-top: 1px solid variables.$ht-navy-200;
}

.border-bottom {
  border-bottom: 1px solid variables.$ht-navy-100;
}

.border-bottom-2 {
  border-bottom: 1px solid variables.$ht-navy-200;
}
