@use 'ht-styleguide/build/variables';
@use 'ht-styleguide/build/spacing';

.headerContainer {
  &.contentFitTabLayout {
    .headerTab {
      flex: 0;
      &:not(:first-of-type) {
        @include spacing.spacing(margin-left, 2);
      }
    }
  }
}

.headerTab {
  flex: 1;
  @include spacing.spacing(padding, 2 0 !important);

  & > p {
    font-weight: variables.$ht-font-weight-medium;
    color: variables.$ht-navy-300;
    white-space: nowrap;
  }
  &.active {
    border-bottom: 2px solid variables.$ht-teal-500;
    & > p {
      color: variables.$ht-navy-900;
    }
  }
}
