@use 'ht-styleguide/build/variables';

/* Floating button */
.floating_button_container {
  position: fixed;
  width: 140px;
  bottom: 30px;
  left: calc(50% - 70px);
  cursor: pointer;

  > button {
    border-radius: 80px;
    box-shadow: variables.$ht-box-shadow-medium;
    letter-spacing: normal;
  }
}
