@use 'sass:map';
@use './grid_mdu';

.grid {
  @include grid_mdu.grid-base();

  @media screen and (min-width: grid_mdu.$grid-xl-begin-margin-auto) {
    margin: 0 auto;
  }
  &-full-width {
    @include grid_mdu.grid-base();

    width: 100%;
    margin: 0;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: map.get(grid_mdu.$default-negative-margins-map, sm);
  margin-right: map.get(grid_mdu.$default-negative-margins-map, sm);
}

.col {
  @include grid_mdu.columns-loop(sm, grid_mdu.$grid-sm-columns, grid_mdu.$grid-sm-gutter);
}
