@use 'ht-styleguide/build/typography_placeholders';
@use 'ht-styleguide/build/spacing';
@use 'ht-styleguide/build/variables';

.toastWrapper {
  font-family: variables.$ht-font-family;
  @extend %h5;
  @include spacing.spacing(padding-right, 4);

  &:global(.Toastify__toast--error) {
    background-color: variables.$ht-alert-red-700;
    color: variables.$ht-white;
    --toastify-icon-color-error: #fff; // can't use sass variables here?
    --toastify-color-progress-error: #fff;
  }
}

.toastCloseButton {
  position: absolute;
  @include spacing.spacing(right, 1.5);
}
