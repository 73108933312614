@use 'sass:map';
@use 'ht-styleguide/build/variables';
@use 'ht-styleguide/build/spacing';

@forward 'reset';

// Design System Specific from ht-styleguide
@forward 'ht-styleguide/build/spacing_classes';
@forward 'ht-styleguide/build/typography_classes';

// Project specific.
@forward 'helpers';
@forward 'grid_mdu_classes';
@forward 'statuses';

*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: border-box;
}

html,
body {
  font-family: variables.$ht-font-family;
  font-weight: variables.$ht-font-weight-regular;
  font-style: normal;

  height: 100%;
  width: 100%;

  @include spacing.spacing(margin, 0);
  @include spacing.spacing(padding, 0);

  border: 0;
  vertical-align: baseline;

  margin: 0;
  padding: 0;

  input,
  button,
  textarea {
    font-family: variables.$ht-font-family;
  }
}

#__next {
  min-height: 100%;
  height: 100%;
  background-color: variables.$ht-white;
}

.page_loader {
  position: fixed !important;
  background-color: variables.$ht-navy-000;
  opacity: 0.7;
  z-index: 9999999999999999999 !important;
}

// need to get at buttons w/o adding additional dom elemnents
// react-swipeable
.swipeable-list-item__trailing-actions .swipe-action:first-of-type button {
  border-top-left-radius: map.get(variables.$ht-panel-border-radius, large);
  border-bottom-left-radius: map.get(variables.$ht-panel-border-radius, large);
}
.swipeable-list-item__trailing-actions .swipe-action:last-of-type button {
  border-top-right-radius: map.get(variables.$ht-panel-border-radius, large);
  border-bottom-right-radius: map.get(variables.$ht-panel-border-radius, large);
}

.swipeable-list-item__leading-actions .swipe-action:first-of-type button {
  border-top-left-radius: map.get(variables.$ht-panel-border-radius, large);
  border-bottom-left-radius: map.get(variables.$ht-panel-border-radius, large);
}
.swipeable-list-item__leading-actions .swipe-action:last-of-type button {
  border-top-right-radius: map.get(variables.$ht-panel-border-radius, large);
  border-bottom-right-radius: map.get(variables.$ht-panel-border-radius, large);
}
